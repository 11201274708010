import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL;

const API = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });

API.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export default API;


// Error handler utility
const handleError = (error) => {
    if (error.response) {
        console.error("Response error:", error.response); // Log the complete response for debugging
        if (error.response.data && (error.response.data.error || error.response.data.msg)) {
            // Check for `error` or `msg` in the response data
            throw new Error(error.response.data.error || error.response.data.msg);
        }
        throw new Error(error.response.statusText || 'Unknown response error');
    } else {
        console.error("Request error:", error); // Log other error information
        throw new Error(error.message || 'Something went wrong');
    }
};


const getAuthHeaders = (contentType = 'application/json') => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('Authorization token is missing. Please log in.');
    }
    return {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': contentType,
        },
    };
};



// Register user
export const registerUser = async (name, email, password, company, budgetDueMonth ) => {
    try {
        const response = await API.post(`${API_URL}/auth/register`, { name, email, password, company, budgetDueMonth });
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

// Login user
export const loginUser = async (email, password) => {
    try {
        const response = await API.post(`${API_URL}/auth/login`, { email, password });
        return response.data;
    } catch (error) {
        handleError(error);
    }
};


// Fetch user information
export const fetchUser = async () => {
    try {
        const config = getAuthHeaders();  // Use the utility function to get auth headers
        const response = await API.get(`${API_URL}/auth/me`, config);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

// Add a new property
export const addProperty = async (propertyData) => {
    try {
        const config = getAuthHeaders();  // Reuse auth header utility
        const response = await API.post(`${API_URL}/properties`, propertyData, config);
        return response.data;  // It's best practice to return response.data directly
    } catch (error) {
        handleError(error);
    }
};
// Delete a property
export const deleteProperty = async (propertyId) => {
    try {
        const config = getAuthHeaders(); // Use getAuthHeaders instead of manually fetching the token
        const response = await API.delete(`${API_URL}/properties/${propertyId}`, config);
        return response.data || { msg: 'Property deleted successfully' };
    } catch (error) {
        handleError(error);
    }
};

// google places
export const fetchPlaceDetails = async (placeId) => {
    try {
        const apiUrl = process.env.REACT_APP_API_BASE_URL; // Ensure this is correctly set in .env
        const response = await API.get(`${apiUrl}/place-details/${placeId}`);
        console.log('Place details fetched:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching place details:', error);
        throw error;
    }
};
// New function to search for places based on a query
export const searchPlaces = async (query) => {
    try {
        const apiUrl = process.env.REACT_APP_API_BASE_URL; // Ensure this is correctly set in .env
        const response = await API.get(`${apiUrl}/search/${query}`);
        return response.data;
    } catch (error) {
        console.error('Error searching for places:', error);
        throw error;
    }
};



// Update property
export const updateProperty = async (propertyId, propertyData) => {
    try {
        const config = getAuthHeaders();
        const response = await API.put(`${API_URL}/properties/update-property/${propertyId}`, propertyData, config);
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Error updating property:', error);
        throw error;
    }
};



// Fetch all users (Admin)
export const fetchUsers = async () => {
    try {
        const config = getAuthHeaders(); // Use utility function
        const response = await API.get(`${API_URL}/admin/users`, config);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};


// Assign estimator to client (Admin)
export const assignEstimatorToClient = async (estimatorId, clientId) => {
    try {
        const token = localStorage.getItem('token');
        const response = await API.post(`${API_URL}/assign`, { estimatorId, clientId }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const addUser = async (userData) => {
    try {
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await API.post(`${API_URL}/users`, userData, config);
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : new Error('Something went wrong');
    }
};

export const updateProfile = async (userId, formData) => {
    try {
        const config = getAuthHeaders('multipart/form-data');
        const response = await API.put(`${API_URL}/profile/profile-update`, formData, config);
        return response.data;
    } catch (error) {
        console.error("Error updating user profile:", error);
        throw error;
    }
};

export const updateUser = async (userId, userData, token) => {
 
    try {
        const response = await API.put(`${API_URL}/users/edit/${userId}`, userData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error updating user profile:", error);
        throw error;
    }
};




export const deleteUser = async (userId) => {
    const token = localStorage.getItem('token');
    return API.delete(`${API_URL}/users/delete/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};



export const assignPropertyToUser = async (propertyId, newUserId) => {
    try {
        const config = getAuthHeaders();
        const response = await API.put(`${API_URL}/properties/assign/${propertyId}`, { newUserId }, config);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

// Assign multiple clients to an estimator
export const assignClientToEstimator = async (estimatorId, clientIds) => {
    try {
        const config = getAuthHeaders();  // DRY usage of header utility
        const response = await API.put(`${API_URL}/users/assign-estimator`, { estimatorId, clientIds }, config);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};
// Fetch properties assigned to a specific user by their ID

export const fetchPropertiesByUserId = async (userId) => {
    try {
        const config = getAuthHeaders();
        const response = await API.get(`${API_URL}/users/properties/${userId}`, config);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            return []; // Return an empty array if no properties are found
        }
        handleError(error);
    }
};

export const fetchAssignedClients = async (estimatorId) => {
    try {
        const config = getAuthHeaders();
        const response = await API.get(`${API_URL}/users/${estimatorId}/clients`, config);
        return response.data;  // Will be an empty array if no clients are assigned
    } catch (error) {
        // If the server returns a 404 error, it means no clients are found
        if (error.response && error.response.status === 404) {
            return [];  // Return an empty array if no clients are found
        }
        handleError(error);  // Handle other errors
    }
};

export const fetchUnassignedClients = async () => {
    try {
        const token = localStorage.getItem('token');
        const response = await API.get(`${API_URL}/users/unassigned-clients`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        handleError(error);
    }
};
// Fetch clients assigned to the estimator
export const fetchClients = async (estimatorId) => {
    try {
        const token = localStorage.getItem('token');  // Get the JWT token from localStorage
        const response = await API.get(`${API_URL}/users/${estimatorId}/clients`, {
            headers: {
                Authorization: `Bearer ${token}`  // Pass the token in the Authorization header
            }
        });
        return response.data;  // Return the data from the server
    } catch (error) {
        // Log the actual error response from the server for debugging
        console.error('Error fetching clients:', error.response ? error.response.data : error.message);
        throw new Error('Failed to fetch clients');
    }
};

export const fetchServiceList = async () => {
    try {
        // Fetch services using the correct endpoint defined in `servicesRoutes`
        const response = await API.get(`${API_URL}/services`);  // Adjusted to match your services route configuration
        return response.data;
    } catch (error) {
        handleError(error);
    }
};


// Fetch a specific budget by property, year, and service
export const fetchPropertyBudget = async (propertyId, year, serviceId) => {
    try {
        const config = getAuthHeaders();
        const response = await API.get(`${API_URL}/budgets/${propertyId}/${year}/${serviceId}`, config);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

// Fetch a specific budget item by budgetId
export const fetchBudgetItemById = async (budgetId) => {
    try {
        const config = getAuthHeaders();
        const response = await API.get(`${API_URL}/budgets/budget/${budgetId}`, config);
        return response.data.budgetItem; // Return the budget item from the response
    } catch (error) {
        handleError(error);
    }
};

// src/services/api.js or a similar file

// Save budget entry for a property
export const savePropertyBudget = async (propertyId, year, formData) => {
    try {
        const config = getAuthHeaders('multipart/form-data');
        const response = await API.post(`${API_URL}/budgets/save-budget/${propertyId}/${year}`, formData, config);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};









// Update an existing budget entry
export const updatePropertyBudget = async (budgetId, budgetData) => {
    try {
        const token = localStorage.getItem('token');
        const response = await API.put(`${API_URL}/budget/${budgetId}`, budgetData, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error('Error updating budget:', error);
        throw error;
    }
};

// Update an existing budget entry
export const updateBudgetItem = async (budgetId, formData) => {
    try {
        const config = getAuthHeaders('multipart/form-data');
        const response = await API.put(`${API_URL}/budgets/update-budget/${budgetId}`, formData, config);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

// Delete a budget item
export const deleteBudgetItem = async (budgetId) => {
    try {
        const config = getAuthHeaders(); // Use your authorization headers
        const response = await API.delete(`${API_URL}/budgets/budget/${budgetId}`, config);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};
export const fetchEstimators = async () => {
    const token = localStorage.getItem('token');
    const response = await API.get(`${API_URL}/users/estimators`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
};

export const fetchPropertyTypes = async () => {
  
    try {
        const response = await API.get(`${API_URL}/property-types`);
        return response.data;
    } catch (error) {
        console.error('Error fetching property types:', error);
        throw error;
    }
};

//get specific property
export const getPropertyById = async (propertyId) => {
    const config = getAuthHeaders();  // Use the utility function to get auth headers

    try {
        const response = await API.get(`${API_URL}/properties/${propertyId}`, config);
        return response.data;
    } catch (error) {
        console.error('Error fetching property:', error);
        throw error;
    }
};

// api.js

export const submitContract = async (contractDetails) => {
    try {
        const config = getAuthHeaders(); // Include the token and headers
        const response = await API.post(`${API_URL}/contracts`, contractDetails, config);
        return response.data; // Assuming the API returns a JSON object with success message
    } catch (error) {
        handleError(error);
    }
};

// Fetch all properties
export const fetchAllProperties = async () => {
    try {
        const config = getAuthHeaders();  // Use the utility function to get auth headers
        const response = await API.get(`${API_URL}/properties`, config);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

// Assign clients to a property
export const assignClientsToProperty = async (propertyId, clientIds) => {
    try {
        const config = getAuthHeaders();
        const response = await API.put(`${API_URL}/properties/${propertyId}/assign-clients`, { clientIds }, config);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

// Fetch all clients
export const fetchAllClients = async () => {
    try {
        const config = getAuthHeaders();
        const response = await API.get(`${API_URL}/users/clients`, config);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};