import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import Loading from './components/commonComponents/Loading';
import ProtectedRoute from './components/ProtectedRoute';
import { LoadScript } from '@react-google-maps/api';
import GenerateMasterContract from './clientSide/components/actionsComponents/GenerateMasterContract';

const Dashboard = lazy(() => import('./clientSide/pages/ClientDashboard.js'));
const BudgetOverview = lazy(() => import('./clientSide/pages/BudgetOverview'));
const ProfilePage = lazy(() => import('./pages/Profile'));
const AdminDashboard = lazy(() => import('./pages/AdminDashboard'));
const EstimatorDashboard = lazy(() => import('./pages/EstimatorDashboard'));
const LandingPage = lazy(() => import('./pages/newRegister')); // Lazy load LandingPage
const PropertyPage = lazy(() => import('./estimatorSide/pages/PropertyPage'));

const libraries = ['places', 'drawing'];
const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function App() {
    return (
        <Router>
            <div className="min-h-screen flex flex-col">
                <LoadScript googleMapsApiKey={apiKey} libraries={libraries}>
                    <main className="flex-grow">
                        <Suspense fallback={<Loading />}>
                            <Routes>
                                <Route path="/" element={<Navigate to="/login" />} />
                                <Route path="/login" element={<Login />} />
                                <Route path="/register" element={<Register />} />
                                
                                {/* Landing page route for new clients */}
                                <Route path="/welcome" element={<LandingPage />} />

                                {/* Protected routes */}
                                <Route path="/admin" element={<ProtectedRoute role="admin"><AdminDashboard /></ProtectedRoute>} />
                                <Route path="/dashboard" element={<ProtectedRoute><Dashboard apiKey={apiKey} /></ProtectedRoute>} />
                                <Route path="/budget" element={<ProtectedRoute><BudgetOverview /></ProtectedRoute>} />
                                <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
                                <Route path="/estimator" element={<ProtectedRoute role="estimator"><EstimatorDashboard apiKey={apiKey} /></ProtectedRoute>} />
                                <Route path="/generate-contract" element={<GenerateMasterContract />} />
                                <Route path="/property-wall" element={<ProtectedRoute role="estimator"><PropertyPage /></ProtectedRoute> }
                                />
                                {/* Catch-all route */}
                                <Route path="*" element={<Navigate to="/login" />} />
                            </Routes>
                        </Suspense>
                    </main>
                </LoadScript>
            </div>
        </Router>
    );
}

export default App;
