import React, { useEffect, useState } from 'react';
import { submitContract, getPropertyById, fetchPropertyBudget, fetchServiceList } from '../../../services/api';

const PLLPP_SERVICE_ID = '671fede672e945489b313bb4';
const PLLPP_PLUS_SERVICE_ID = '671fede672e945489b313bbf';

const formatCurrency = (value) => `$${value?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;

const GenerateMasterContract = ({ selectedYear = new Date().getFullYear() }) => {
    const [isGenerating, setIsGenerating] = useState(false);
    const [signature, setSignature] = useState('');
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [contractData, setContractData] = useState(null);
    const [properties, setProperties] = useState([]);
    const [pllppPrice, setPllppPrice] = useState({});
    const [pllppPlusBasePrice, setPllppPlusBasePrice] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [serviceMappings, setServiceMappings] = useState({});
    const [customerName, setCustomerName] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [signatoryTitle, setSignatoryTitle] = useState('');
    const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';
    const [selectedBudgetItems, setSelectedBudgetItems] = useState([]);
    const [propertyMappings, setPropertyMappings] = useState({});
    const [contractDates, setContractDates] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const storedData = JSON.parse(localStorage.getItem('contractData'));
            console.log('Loaded contract data:', storedData);
            console.log('Selected Budget IDs:', storedData?.selectedBudgetIds);

            if (storedData) {
                setContractData(storedData);
                await fetchPropertiesData(storedData.properties);
                setCustomerName(storedData.customerName || '');
                setContactPerson(storedData.contactPerson || '');
                setSignatoryTitle(storedData.signatoryTitle || '');
                await fetchServiceMappings();
            }
            setIsLoading(false);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (contractData) {
            const updatedContractData = {
                ...contractData,
                customerName,
                contactPerson,
                signatoryTitle,
            };
            localStorage.setItem('contractData', JSON.stringify(updatedContractData));
        }
    }, [customerName, contactPerson, signatoryTitle]);

    useEffect(() => {
        if (contractData) {
            const selectedBudgetItemIds = Object.values(contractData.selectedBudgetIds).flat();
            const selectedItems = contractData.budgetItems.filter(item => selectedBudgetItemIds.includes(item._id));
            setSelectedBudgetItems(selectedItems);
        }
    }, [contractData]);

    const fetchPropertiesData = async (propertyIds) => {
        const propertyArray = [];
        const mappings = {};

        try {
            for (const propertyId of Object.keys(propertyIds)) {
                const property = await getPropertyById(propertyId);
                propertyArray.push(property);
                mappings[propertyId] = property.name;

                await fetchBasePrices(propertyId);
            }
            setProperties(propertyArray);
            setPropertyMappings(mappings);

        } catch (error) {
            console.error('Error fetching properties:', error);
        }
    };

    const fetchBasePrices = async (propertyId) => {
        try {
            const pllppBudget = await fetchPropertyBudget(propertyId, selectedYear, PLLPP_SERVICE_ID);
            const pllppPlusBudget = await fetchPropertyBudget(propertyId, selectedYear, PLLPP_PLUS_SERVICE_ID);

            console.log(`pllppBudget for property ${propertyId}:`, pllppBudget);
            console.log(`pllppPlusBudget for property ${propertyId}:`, pllppPlusBudget);

            setPllppPrice((prev) => ({
                ...prev,
                [propertyId]: pllppBudget?.budgetItems?.reduce((sum, item) => sum + (item.totalPrice || 0), 0) || 0,
            }));

            setPllppPlusBasePrice((prev) => ({
                ...prev,
                [propertyId]: pllppPlusBudget?.budgetItems?.reduce((sum, item) => sum + (item.totalPrice || 0), 0) || 0,
            }));
        } catch (error) {
            console.error(`Error fetching budget for property ${propertyId}:`, error);
        }
    };

    const fetchServiceMappings = async () => {
        try {
            const services = await fetchServiceList(); // Fetch all services
            const mappings = {};
            services.forEach((service) => {
                mappings[service._id] = service.name;
            });
            setServiceMappings(mappings);
            console.log('Service Mappings:', mappings);
        } catch (error) {
            console.error('Error fetching services:', error);
        }
    };

    const handleDateChange = (propertyId, date) => {
        setContractDates((prevDates) => {
            const startDate = date;
            const endDate = new Date(startDate);
            endDate.setFullYear(endDate.getFullYear() + 1);
            return {
                ...prevDates,
                [propertyId]: {
                    startDate,
                    endDate: endDate.toISOString().split('T')[0],
                },
            };
        });
    };

    const handleSignatureChange = (e) => setSignature(e.target.value);

    const calculateTotalAndServices = (propertyId, contractType) => {
        console.log(`Calculating total for property ${propertyId} with contract type ${contractType}`);

        console.log('pllppPrice:', pllppPrice);
        console.log('pllppPlusBasePrice:', pllppPlusBasePrice);

        const basePrice = contractType === 'pllpp' ? pllppPrice[propertyId] || 0 : pllppPlusBasePrice[propertyId] || 0;

        console.log(
            `Base Price for ${contractType} contract on property ${propertyId}:`,
            formatCurrency(basePrice)
        );

        const selectedBudgetIdsForProperty = contractData?.selectedBudgetIds?.[propertyId] || [];

        let additionalServiceItems = [];
        if (contractType === 'pllppPlus' && selectedBudgetIdsForProperty.length > 0) {
            additionalServiceItems = contractData.budgetItems.filter(
                (item) =>
                    item.property_id === propertyId &&
                    item.service_id !== PLLPP_SERVICE_ID &&
                    item.service_id !== PLLPP_PLUS_SERVICE_ID &&
                    selectedBudgetIdsForProperty.includes(item._id)
            );
            console.log(
                `Filtered Additional Services for PLLPP+ on property ${propertyId}:`,
                additionalServiceItems
            );
        }

        // Use serviceMappings to get service names
        const additionalServices = additionalServiceItems
            .map((item) => serviceMappings[item.service_id])
            .filter((name) => name);

        const uniqueServices = [...new Set(additionalServices)].join(', ') || 'N/A';

        const totalPrice =
            basePrice + additionalServiceItems.reduce((sum, item) => sum + (item.totalPrice || 0), 0);
        const monthlyPrice = totalPrice / 12;

        console.log(`Total Price for property ${propertyId}:`, formatCurrency(totalPrice));
        console.log(`Monthly Price for property ${propertyId}:`, formatCurrency(monthlyPrice));
        console.log(`Services for property ${propertyId}:`, uniqueServices);

        return { totalPrice, monthlyPrice, services: uniqueServices, basePrice };
    };

    const handleGenerateContract = async () => {
        if (!signature) {
            alert('Please provide a signature by typing your full name.');
            return;
        }

        if (!agreedToTerms) {
            alert('You must agree to the terms and conditions to proceed.');
            return;
        }

        if (!customerName || !contactPerson) {
            alert('Please provide the company name and contact person.');
            return;
        }

        // Initialize variables to compute earliest and latest dates
        let earliestStartDate = null;
        let latestEndDate = null;

        // Prepare properties data for submission
        const propertiesData = properties.map((property) => {
            const contractType = contractData?.properties?.[property._id];
            const { totalPrice, monthlyPrice, services, basePrice } = calculateTotalAndServices(
                property._id,
                contractType
            );

            const propertyDates = contractDates[property._id];
            if (!propertyDates || !propertyDates.startDate) {
                alert(`Please set the contract start date for property ${property.name}.`);
                throw new Error(`Missing start date for property ${property.name}`);
            }

            const { startDate, endDate } = propertyDates;
            const startDateObj = new Date(startDate);
            const endDateObj = new Date(endDate);

            // Update earliestStartDate and latestEndDate
            if (!earliestStartDate || startDateObj < earliestStartDate) {
                earliestStartDate = startDateObj;
            }
            if (!latestEndDate || endDateObj > latestEndDate) {
                latestEndDate = endDateObj;
            }

            return {
                _id: property._id,
                name: property.name,
                address: property.address,
                contractType: contractType === 'pllppPlus' ? 'PLLPP+' : 'PLLPP',
                services,
                totalPrice,
                monthlyPrice,
                basePrice, // Add this line
                contractStartDate: startDate,
                contractEndDate: endDate,
            };
        });

        // Convert earliest and latest dates to ISO strings
        const contractStartDate = earliestStartDate.toISOString().split('T')[0];
        const contractEndDate = latestEndDate.toISOString().split('T')[0];

        const signedAt = new Date().toISOString();
        setIsGenerating(true);
        try {
            // Prepare the data to be submitted
            const contractDetails = {
                customerName,
                contactPerson,
                signatoryTitle,
                signature,
                agreedToTerms,
                contractStartDate,
                contractEndDate,
                properties: propertiesData,
                totalPrice: propertiesData.reduce((sum, prop) => sum + (prop.totalPrice || 0), 0),
                monthlyPrice: propertiesData.reduce((sum, prop) => sum + (prop.monthlyPrice || 0), 0),
                services: propertiesData.flatMap((prop) => prop.services.split(', ')),
                selectedYear,
                selectedBudgetItems, // Include if needed
                signedAt,
            };

            const response = await submitContract(contractDetails);
            alert(response.success ? response.message : response.message);
        } catch (error) {
            console.error('Error generating contract:', error);
            alert('Failed to generate contract. Please try again later.');
        } finally {
            setIsGenerating(false);
        }
    };

    const renderFile = (fileUrl) => {
        const fullUrl = `${baseUrl}/uploads/${fileUrl}`;
        const fileExtension = fileUrl.split('.').pop().toLowerCase();

        if (['png', 'jpg', 'jpeg', 'gif'].includes(fileExtension)) {
            // Display image centered
            return (
                <div className="flex justify-center">
                    <img src={fullUrl} alt="File" className="max-w-full h-auto" />
                </div>
            );
        } else if (fileExtension === 'pdf') {
            // Display PDF
            return (
                <iframe
                    src={fullUrl}
                    title="PDF File"
                    width="100%"
                    height="600px"
                    className="border"
                ></iframe>
            );
        } else {
            // Provide a download link for other file types
            return (
                <a
                    href={fullUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                >
                    Download File
                </a>
            );
        }
    };

    const getTermDates = (startDate, yearsToAdd) => {
        if (!startDate) {
            return { startDate: '', endDate: '' };
        }
        const start = new Date(startDate);
        start.setFullYear(start.getFullYear() + yearsToAdd);
        const end = new Date(start);
        end.setFullYear(end.getFullYear() + 1);
        return {
            startDate: start.toISOString().split('T')[0],
            endDate: end.toISOString().split('T')[0],
        };
    };

    if (isLoading || !contractData || properties.length === 0 || Object.keys(serviceMappings).length === 0) {
        return <div>Loading...</div>;
    }



    return (
        <div className="p-8 bg-white rounded-md shadow-lg max-w-4xl mx-auto mt-10">
           {/* Header with Logo, Contact Info, and Title */}
            <div className="flex items-center justify-between mb-6">
                <div>
                    <h1 className="text-3xl font-bold">Master Contract for {selectedYear}</h1>
                    <p className="text-sm mt-1">Office: 253-887-9501</p>
                    <p className="text-sm">2118 A Street S.E., Auburn, WA 98002</p>
                    <p className="text-sm">Emergency: 253-263-2155</p>
                </div>
                <img src="/acmoate.png" alt="Company Logo" className="h-16 w-auto" />
            </div>
            <div className="border-b border-gray-300 my-6">
                <h2 className="text-lg font-semibold mb-2">Customer Information</h2>
                <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2" htmlFor="customerName">
                        Name (Company Name)
                    </label>
                    <input
                        type="text"
                        id="customerName"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        placeholder="Enter company name"
                        className="border p-2 w-full mt-2 rounded-md"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2" htmlFor="contactPerson">
                        Contact
                    </label>
                    <input
                        type="text"
                        id="contactPerson"
                        value={contactPerson}
                        onChange={(e) => setContactPerson(e.target.value)}
                        placeholder="Enter contact person"
                        className="border p-2 w-full mt-2 rounded-md"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2" htmlFor="signatoryTitle">
                        Title
                    </label>
                    <input
                        type="text"
                        id="signatoryTitle"
                        value={signatoryTitle}
                        onChange={(e) => setSignatoryTitle(e.target.value)}
                        placeholder="Enter your title"
                        className="border p-2 w-full mt-2 rounded-md"
                    />
                </div>
            </div>

            <div className="border-b border-gray-300 my-6">
                <h2 className="text-lg font-semibold mb-2">Selected Properties</h2>

                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 px-2 py-1">Property Name</th>
                            <th className="border border-gray-300 px-2 py-1">Property Address</th>
                            <th className="border border-gray-300 px-2 py-1">Contract Type</th>
                            <th className="border border-gray-300 px-2 py-1">Additional Services</th>
                            <th className="border border-gray-300 px-2 py-1">Contract Start Date</th>
                            <th className="border border-gray-300 px-2 py-1 w-1/4">Term: {selectedYear}</th>
                            <th className="border border-gray-300 px-2 py-1 w-1/4">Term: {selectedYear + 1}</th>
                            <th className="border border-gray-300 px-2 py-1 w-1/4">Term: {selectedYear + 2}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {properties.map((property) => {
                            const contractType = contractData?.properties?.[property._id];
                            if (contractType) {
                                const { totalPrice, monthlyPrice, services, basePrice } = calculateTotalAndServices(
                                    property._id,
                                    contractType
                                );

                                const propertyDates = contractDates[property._id] || {};
                                const startDate = propertyDates.startDate;
                                const endDate = propertyDates.endDate;

                                const firstYearTerm = {
                                    startDate: startDate,
                                    endDate: endDate,
                                };

                                const secondYearTerm = getTermDates(startDate, 1);
                                const thirdYearTerm = getTermDates(startDate, 2);

                                // Conditionally set the row class
                                const rowClass = `text-sm border-t border-gray-300 hover:bg-gray-100 ${startDate ? 'odd:bg-gray-50' : 'bg-red-200'
                                    }`;
                                return (
                                    <tr key={property._id} className={rowClass}>
                                        <td className="border border-gray-300 px-1 py-1">{property.name}</td>
                                        <td className="border border-gray-300 px-1 py-1">{property.address}</td>
                                        <td className="border border-gray-300 px-1 py-1">{contractType === 'pllppPlus' ? 'PLLPP+' : 'PLLPP'}</td>
                                        <td className="border border-gray-300 px-1 py-1">{services}</td>
                                        <td className="border border-gray-300 px-1 py-1">
                                            <input
                                                type="date"
                                                value={startDate || ''}
                                                onChange={(e) => handleDateChange(property._id, e.target.value)}
                                                className="border p-1 rounded"
                                            />
                                        </td>
                                        {/* Column for selectedYear */}
                                        <td className="border border-gray-300 px-1 py-1 w-1/4">
                                            {startDate && endDate ? (
                                                <>
                                                    <div>{firstYearTerm.startDate} - {firstYearTerm.endDate}</div>
                                                    <div>Total:</div>
                                                    <div>{formatCurrency(totalPrice)}</div>
                                                    <div>Monthly: {formatCurrency(monthlyPrice)}</div>
                                                </>
                                            ) : (
                                                <div>Please select a start date.</div>
                                            )}
                                        </td>
                                        {/* Column for selectedYear +1 */}
                                        <td className="border border-gray-300 px-1 py-1 w-1/4">
                                            {startDate ? (
                                                <>
                                                    <div>
                                                        {secondYearTerm.startDate} - {secondYearTerm.endDate}
                                                    </div>
                                                    <div>Total:</div>
                                                    <div>{formatCurrency(basePrice)}</div>
                                                    <div>Monthly: {formatCurrency(basePrice / 12)}</div>
                                                </>
                                            ) : (
                                                <div>Please select a start date.</div>
                                            )}
                                        </td>
                                        {/* Column for selectedYear +2 */}
                                        <td className="border border-gray-300 px-1 py-1 w-1/4">
                                            {startDate ? (
                                                <>
                                                    <div>{thirdYearTerm.startDate} - {thirdYearTerm.endDate}</div>
                                                    <div>Total:</div>
                                                    <div>{formatCurrency(basePrice)}</div>
                                                    <div>Monthly: {formatCurrency(basePrice / 12)}</div>
                                                </>
                                            ) : (
                                                <div>Please select a start date.</div>
                                            )}
                                        </td>
                                    </tr>
                                );
                            }
                            return null;
                        })}
                    </tbody>
                </table>
            </div>

            <div className="border-b border-gray-300 my-6">

                {/* PLLPP Terms */}
                <h3 className="text-lg font-semibold mb-2 bg-yellow-300 p-2 rounded">PLLPP Terms</h3>
                <p className="mb-4">
                    PLLPP Includes automatic winter services, annual crackfilling, 2x a month sweeping,
                    broken curb pick up, and emergency temporary pothole patch (Potholes size will be a 6'
                    x 6' or less).
                </p>
                <ul className="list-disc ml-6 mb-4 text-sm">
                    <li>
                        <strong>Snow Plowing:</strong> Unlimited snowplowing (Guaranteed service once every 24
                        hours).
                    </li>
                    <li>
                        <strong>De-icing:</strong> Unlimited de-icing of parking lot and walkways (if applicable)
                        serviced automatically at 33 degrees or below. (Guaranteed service once every 24
                        hours).
                    </li>
                    <li>
                        <strong>Walkway Clearing:</strong> Unlimited walkway clearing (Guaranteed service once
                        every 24 hours - if applicable).
                    </li>
                    <li>
                        <strong>Crackfill:</strong> Unlimited singular crack repair using hot applied rubberized
                        synthetic crackfill material (We do not crackfill alligatored or spiderwebbed areas).
                    </li>
                    <li>
                        <strong>Potholes:</strong> Emergency temporary pothole patch (Potholes size will be a 6' x
                        6' or less).
                    </li>
                </ul>

                {/* PLLPP+ Terms */}
                <h3 className="text-lg font-semibold mb-2 bg-yellow-300 p-2 rounded">PLLPP+</h3>
                <p className="mb-4">
                    Base PLLPP+ Includes Sealcoat, Striping, Pressure Washing, and PLLPP. 3-year warranty on
                    all work included in the PLLPP+ contract. Add-on items are listed, and the scope of work is
                    attached on the following pages of the contract.
                </p>
                <ul className="list-disc ml-6 mb-4 text-sm">
                    <li>
                        <strong>Sealcoat:</strong> Entire parking lot will be sealcoated using the highest quality
                        asphalt sealing product. This is an asphalt emulsion designed to seal the asphalt and
                        give it a beautiful black finish. The first coat of sealer will be applied with a squeegee
                        machine under constant agitation. 70% of the sealcoat to be applied goes down in the
                        squeegee coat. The squeegees force the material into the asphalt voids to make a proper
                        seal. The second coat of sealer is applied by spray. 30% of the required material goes
                        down in the spray coat. The spray coat is designed to give the job a beautiful even finish.
                        After sealcoat completion, the striping will be completed next.
                    </li>
                    <li>
                        <strong>Striping:</strong> Entire parking lot will be striped using the highest quality
                        traffic parking paint and applied using a Graco LineLazer sprayer.
                    </li>
                    <li>
                        <strong>Annual Pressure Washing:</strong> Exterior sidewalks where accessible will be
                        power washed using up to 5000 psi of water. All pressure washing debris will be cleaned
                        up when we are finished.
                    </li>
                    <li>
                        All other items have their own scopes of work attached to this contract and are eligible
                        for a three-year warranty.
                    </li>
                </ul>
                {/* New Tier 1 Rate Sheet Winter Service Rates Section */}
                <h3 className="text-lg font-semibold mb-2 bg-yellow-300 p-2 rounded">Tier 1 Rate Sheet Winter Service Rates</h3>
                <ul className="list-disc ml-6 mb-4 text-sm">
                    <li>
                        <strong>Snow Plowing:</strong> $300.00 an hour with a 2-hour minimum per service.
                    </li>
                    <li>
                        <strong>Sidewalk Clearing:</strong> $200.00 an hour with a 1-hour minimum per service.
                    </li>
                    <li>
                        <strong>De-icing:</strong> $175.00 an hour with a 1-hour minimum per service.
                    </li>

                    <li>
                        <strong>De-icer Material:</strong> $1.30 per pound.
                    </li>
                    <li>
                        <strong>Site Inspection:</strong> $75.00 Per Mobilization.
                    </li>
                </ul>

                {/* Contract Terms & Conditions */}
                <h2 className="text-lg font-semibold mb-2 bg-yellow-300 p-2 rounded">Contract Terms & Conditions</h2>

                {/* Payment Disclaimer */}
                <h3 className="text-sm font-semibold mb-2">Payment Disclaimer</h3>
                <ol className="list-decimal ml-6 mb-4 text-sm">
                    <li>Payment terms are as follows: Net 30.</li>
                    <li>
                        You are hereby notified that a material man's and/or a mechanic's lien may be filed to
                        secure payment for work rendered or products sold on letter releasing all liens or lien
                        rights upon full payment of balance due.
                    </li>
                    <li>
                        2% per month (24% APR) interest will accrue on all balances 30 days from the billing
                        date.
                    </li>
                    <li>Customer agrees to pay all attorney's fees and costs of collection.</li>
                    <li>
                        Exclusions: Permits, traffic control, sales tax where applicable, and anything else not
                        specifically mentioned in this estimate.
                    </li>
                    <li>
                        Additional terms & conditions apply based on the included estimates in the following
                        pages.
                    </li>
                </ol>

                {/* Cancellation Policy */}
                <h3 className="text-sm font-semibold mb-2">Cancellation Policy</h3>
                <p className="mb-4 text-sm">
                    You have the right to cancel the contract at any time with a written 30-day notice. However,
                    cancellation fees apply as follows:
                </p>
                <p className="mb-4 text-sm">
                    If you cancel the contract, you are required to pay for the full amount of the work completed
                    up to the date of cancellation, based on the services rendered.
                </p>
                <p className="mb-4 text-sm">The total payment due will be determined by:</p>
                <ol className="list-decimal ml-6 mb-4 text-sm">
                    <li>
                        The services completed charged based on winter services tier 1 rates shown on the winter
                        service rate sheets, and all other services completed and costs provided in the following
                        pages.
                    </li>
                    <li>Subtracting any payments already made under the contract.</li>
                </ol>
                <p className="mb-4 text-sm">
                    Any remaining unpaid balance for services completed will be due immediately upon
                    cancellation.
                </p>

                {/* Termination Due to Non-Payment */}
                <h3 className="text-sm font-semibold mb-2">Termination Due to Non-Payment</h3>
                <ul className="list-disc ml-6 mb-4 text-sm">
                    <li>
                        <strong>Automatic Termination:</strong> Failure to meet the payment terms will result in
                        automatic termination of the contract.
                    </li>
                    <li>
                        <strong>Payment for Completed Work:</strong> Even in the event of termination due to
                        non-payment, you are still required to pay the full balance for work completed up to the
                        date of termination.
                    </li>
                    <li>
                        <strong>Lien Rights:</strong> A lien may be placed on the property to secure payment for
                        any outstanding balances for services that have been rendered.
                    </li>
                </ul>

                {/* Overriding Clause */}
                <h3 className="text-sm font-semibold mb-2">Overriding Clause</h3>
                <p className="mb-4 text-sm">
                    <strong>Precedence of Terms:</strong> In the event of any conflict between the terms outlined
                    in this Payment Disclaimer and the terms in the following pages, the terms in this Payment
                    Disclaimer will take precedence. All other terms and conditions in the addenda not in direct
                    conflict with this disclaimer will remain in effect.
                </p>
                {/* Estimates and Sitemaps Section */}
                {selectedBudgetItems.length > 0 && (
                    <div className="border-b border-gray-300 my-6">
                        <h2 className="text-lg font-semibold mb-2">Estimates and Sitemaps</h2>
                        {selectedBudgetItems.map((item, index) => (
                            <div key={item._id || index} className="mb-4">
                                <h3 className="text-md font-semibold mb-2">
                                    Service: {serviceMappings[item.service_id] || 'Unknown Service'} - {propertyMappings[item.property_id] || 'Unknown Property'}
                                </h3>

                                {/* Estimates */}
                                {item.estimates && item.estimates.length > 0 && (
                                    <div className="mb-2">
                                        <h4 className="text-sm font-semibold">Estimates:</h4>
                                        {item.estimates.map((estimate, idx) => (
                                            <div key={estimate._id || idx} className="mt-2">
                                                {renderFile(estimate.fileUrl)}
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {/* Sitemaps */}
                                {item.sitemaps && item.sitemaps.length > 0 && (
                                    <div className="mb-2">
                                        <h4 className="text-sm font-semibold">Sitemaps:</h4>
                                        {item.sitemaps.map((sitemap, idx) => (
                                            <div key={sitemap._id || idx} className="mt-2">
                                                {renderFile(sitemap.fileUrl)}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
                {/* Acknowledgment */}
                <div className="border border-gray-300 p-4 rounded-md mt-4 bg-gray-50">
                    <h3 className="text-md font-semibold mb-2">Acknowledgment</h3>
                    <p className="text-sm">
                        By signing this agreement, you acknowledge that you have read, understood, and agree to the payment terms...
                    </p>
                </div>
            </div>

            <div className="border-b border-gray-300 my-6">
                <h2 className="text-lg font-semibold mb-2">Signatures</h2>
                <p>
                    By typing your name below and checking the box, you agree to the terms and conditions and consent to sign electronically.
                </p>
                <input
                    type="text"
                    id="signature"
                    value={signature}
                    onChange={(e) => setSignature(e.target.value)}
                    placeholder="Type your full name"
                    className="border border-gray-300 p-2 w-full mt-2 rounded-md"
                />
                <label className="flex items-center mt-4 space-x-2 text-sm text-gray-700">
                    <input
                        type="checkbox"
                        checked={agreedToTerms}
                        onChange={(e) => setAgreedToTerms(e.target.checked)}
                        className="form-checkbox h-4 w-4 text-blue-500 rounded"
                    />
                    <span>
                        I agree to the <a href="#" className="text-blue-600 hover:underline">terms and conditions</a> and consent to sign electronically.
                    </span>
                </label>
            </div>
            {/* Company Signature Section */}
            <div className="border-b border-gray-300 my-6">
                <h2 className="text-lg font-semibold mb-2">Company Signature</h2>
                <p className="mb-4">
                    This contract requires a signature from an authorized representative of A.C. Moate Industries Inc. Please print this page and sign below.
                </p>
                <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2">
                        Company Name:
                    </label>
                    <p className="text-gray-900">A.C. Moate Industries Inc</p>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2">
                        Representative Name:
                    </label>
                    <p className="text-gray-900"></p>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2">
                        Title:
                    </label>
                    <p className="text-gray-900"></p>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2">
                        Signature:
                    </label>
                    <div className="border-t border-gray-500 mt-8 w-64"></div>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2">
                        Date:
                    </label>
                    <p className="text-gray-900"></p>
                </div>
            </div>
            <button
                onClick={handleGenerateContract}
                className={`bg-blue-500 text-white font-semibold px-4 py-2 mt-4 rounded ${isGenerating ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
                disabled={isGenerating}
            >
                {isGenerating ? 'Generating...' : 'Generate Master Contract'}
            </button>

            {/* Spinner Overlay */}
            {isGenerating && (
                <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
                    <div className="loader"></div>
                </div>
            )}

            {/* Spinner CSS */}
            <style>{`
                .loader {
                    border: 8px solid #f3f3f3;
                    border-top: 8px solid #3498db;
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    animation: spin 1s linear infinite;
                }

                @keyframes spin {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
            `}</style>
        </div>
      

    );
};

export default GenerateMasterContract;
